import React from "react";
import Section from "../components/Section";
import { Link } from "react-router-dom";
import ActionButton from "../components/ActionButton";
import Card from "../components/Card";
import projectData from "../db/db";
import { Helmet } from "react-helmet-async";

const Homepage = () => {
  const titleSectionContent = (
    <div className="flex flex-col gap-4 sm:flex-row justify-between">
      <p className="text-lg">
        I am an aspiring UX Designer and software developer
        <br />
        based in London.
      </p>
      <Link
        to="https://drive.google.com/file/d/1QHZNyiH5ysxqxqzVNPadWS-bEfl5WWUG/view?usp=sharing"
        target="blank"
      >
        <ActionButton
          content="View My CV"
          shadowColor="shadow-teal-400"
          textColor="text-teal-400"
        />
      </Link>
    </div>
  );

  const skillsContent = (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:text-xl uppercase">
      <div className="p-2 md:p-4 lg:p-6 border-x border-t border-white bg-white/30 md:border">
        <h3 className="pb-1 font-semibold tracking-normal">Languages</h3>
        <ul>
          <li className="tracking-normal">Javascript, Typescript</li>
          <li className="tracking-normal">Python</li>
          <li className="tracking-normal">SQL</li>
          <li className="tracking-normal">HTML,CSS</li>
          <li className="tracking-normal">Google Apps Script</li>
        </ul>
      </div>
      <div className="p-2 md:p-4 lg:p-6 border-x border-t border-white bg-white/30 md:border">
        <h3 className="pb-1 font-semibold tracking-normal">Frameworks</h3>
        <ul>
          <li className="tracking-normal">React, NextJS</li>
          <li className="tracking-normal">Node</li>
          <li className="tracking-normal">Express</li>
          <li className="tracking-normal">Flask, Django</li>
          <li className="tracking-normal">Angular</li>
          <li className="tracking-normal">Bootstrap, TailwindCSS</li>
        </ul>
      </div>
      <div className="p-2 md:p-4 lg:p-6 border-x border-t border-white bg-white/30 sm:border">
        <h3 className="pb-1 font-semibold tracking-normal">Tools</h3>
        <ul>
          <li className="tracking-normal">Git, Github</li>
          <li className="tracking-normal">Visual Studio Code</li>
          <li className="tracking-normal">Figma</li>
          <li className="tracking-normal">Docker</li>
          <li className="tracking-normal">M365 tools</li>
          <li className="tracking-normal">Azure Devops, Trello, Asana</li>
        </ul>
      </div>
      <div className="p-2 md:p-4 lg:p-6 border border-white bg-white/30">
        <h3 className="pb-1 font-semibold tracking-normal">Other</h3>
        <ul>
          <li className="tracking-normal">Digital Marketing</li>
          <li className="tracking-normal pl-3">
            Paid Search (Google, Bing, Amazon)
          </li>
          <li className="tracking-normal pl-3">
            Amazon Seller and Vendor Central
          </li>
          <li className="tracking-normal pl-3">
            Paid Social (Meta, Twitter, Instagram)
          </li>
          <li className="tracking-normal">Google Analytics, Looker Studio</li>
        </ul>
      </div>
    </div>
  );

  const projectContent = (
    <Link to="/projects">
      <ActionButton
        content="See More"
        shadowColor="shadow-amber-400"
        textColor="text-amber-400"
      />
    </Link>
  );

  return (
    <>
      <Helmet>
        <title>Sheena Diep</title>
      </Helmet>
      <Section
        sectionStyle="bg-white min-h-[58vh] sm:min-h-[65vh] lg:min-h-[68vh]"
        title="Hello, I'm"
        subtitle="SHEENA DIEP"
        subtitleStyle="text-gradient"
        titleBorderStyle="border-gradient"
        children={titleSectionContent}
      />
      <Section
        sectionStyle="bg-gradient min-h-[84vh]"
        title="Skills"
        titleStyle="text-white"
        titleBorderStyle="border-white"
        children={skillsContent}
      />
      <Section
        bgColor="bg-white"
        title="Projects"
        titleBorderStyle="border-gradient"
        children={projectContent}
      />
      <section className="w-full grid grid-cols-1 sm:grid-cols-2">
        <Card bgColor="bg-teal-300" project={projectData[0]} id={0} />
        <Card bgColor="bg-sky-300" project={projectData[1]} id={1} />
        <Card bgColor="bg-amber-300" project={projectData[2]} id={2} />
        <Card bgColor="bg-rose-300" project={projectData[3]} id={3} />
      </section>
    </>
  );
};

export default Homepage;
