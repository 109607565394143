import React from "react";
import Section from "../components/Section";
import { Link } from "react-router-dom";
import ActionButton from "../components/ActionButton";
import { Helmet } from "react-helmet-async";

const Aboutpage = () => {
  const content = (
    <Link
      to="https://drive.google.com/file/d/1QHZNyiH5ysxqxqzVNPadWS-bEfl5WWUG/view?usp=sharing"
      target="blank"
    >
      <ActionButton
        content="View My CV"
        shadowColor="shadow-violet-400"
        textColor="text-violet-400"
      />
    </Link>
  );

  const aboutText = (
    <>
      <p className="text-white text-sm md:text-base xl:text-lg">
        I am a career changer, looking for a role in tech. I have an interest in
        UX design, Devops, analytics and automation.
        <br />
        <br />I have 3 years agency experience in digital marketing, managing
        paid search for a range of brands on Google, Bing and Amazon.
        <br />
        <br />
        My journey began with a 13-week bootcamp where I learnt HTML, CSS,
        JavaScript, Python, as well as front and back end frameworks, Docker,
        databases. I have worked in SCRUM team, using tools such as Figma and
        Github.
        <br />
        <br />I have expeirence in a 3rd-line IT, where I oversaw the
        development of collaboration tools in the enterprise including the M356
        estate, produced techical and user documentation.
      </p>
    </>
  );
  return (
    <>
      <Helmet>
        <title>Sheena Diep | About</title>
      </Helmet>
      <Section
        title="About"
        titleBorderStyle="border-gradient"
        children={content}
      />
      <Section
        sectionStyle="bg-blue-950"
        titleBorderStyle="border-none"
        children={aboutText}
      />
    </>
  );
};

export default Aboutpage;
