import React from "react";
import Tags from "./Tags";
import Icon from "./Icon";
import Image from "./Image";

const Card = ({ project, bgColor, id }) => {
  return (
    <article className="relative overflow-hidden h-72 xl:h-96 flex flex-wrap">
      <Image project={project} />
      <div
        className={`${bgColor} w-full p-6 md:p-8 xl:p-10 h-72 xl:h-96 flex flex-col gap-2 absolute transition duration-300 transform translate-y-full image-overlay`}
      >
        <h2 className="font-bold text-base sm:text-sm lg:text-xl xl:text-2xl tracking-normal border-b-2 border-white">
          {project.title}
        </h2>
        <p className="tracking-normal font-semibold italic text-sm lg:text-lg xl:text-xl">
          {project.subtitle}
        </p>
        <p className="overflow-hidden text-wrap truncate text-xs lg:text-sm xl:text-lg tracking-normal">
          {project.description}
        </p>
        <div className="h-12">
          <div className="flex gap-2 flex-wrap">
            {project.technologies.map((tech, i) => {
              return (
                <Tags
                  key={`${project.name}_${i}`}
                  name={tech.name}
                  type={tech.type}
                />
              );
            })}
          </div>
        </div>
        <div className="flex justify-start gap-5">
          {project.icons.map((icon, i) => {
            return <Icon icon={icon} key={i} id={id} />;
          })}
        </div>
      </div>
    </article>
  );
};

export default Card;
