import React, { useState } from "react";
import projectData from "../db/db";
import Tags from "../components/Tags";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./tabs.css";
import CurrentGuestAccess from "../assets/projects/guestaccess/CurrentGuestFlow.png";
import NewGuestAccess from "../assets/projects/guestaccess/NewGuestFlow.png";

const GuestAccess = () => {
  const url = window.location.href;

  const index = url.indexOf("/projects/");
  let id = url[index + 10];

  const technologies = [
    { name: "power apps", type: "client" },
    { name: "azure data studio", type: "database" },
    { name: "sql", type: "language" },
    { name: "power automate", type: "other" },
    { name: "scorch", type: "other" },
    { name: "azure devops", type: "other" },
  ];

  const [showFlow, setShowFlow] = useState(false);
  const handleShowFlow = () => {
    setShowFlow(!showFlow);
  };

  const [showSchema, setShowSchema] = useState(false);
  const handleShowSchema = () => {
    setShowSchema(!showSchema);
  };

  const [showCode, setShowCode] = useState(false);
  const handleShowCode = () => {
    setShowCode(!showCode);
  };

  const [showProto, setShowProto] = useState(false);
  const handleShowProto = () => {
    setShowProto(!showProto);
  };

  return (
    <>
      <section className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20`}>
        <div
          className={`text-3xl xl:text-5xl border-b-2 pb-2 font-semibold border-gradient`}
        >
          {projectData[id].title}
        </div>
        <div
          className={`text-xl xl:text-5xl pt-4 h-auto grid grid-cols-2 md:grid-cols-4 gap-2 text-center`}
        >
          <a href="#problem" className="uppercase font-extralight">
            problem
          </a>
          <a href="#requirements" className="uppercase font-extralight">
            requirements
          </a>
          <a href="#solution" className="uppercase font-extralight">
            solution
          </a>
          <a href="#problem" className="uppercase font-extralight">
            review
          </a>
        </div>
      </section>
      <section
        className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20 bg-blue-950 text-white`}
      >
        <div className="flex flex-col gap-4 text-white">
          <div className="lg:col-span-5 flex flex-col gap-4">
            <p className="font-light lg:text-lg">
              As part of the IT development team, I had the honour of working on
              this project, which was particularly special as the team often
              work in silos. During this project, we all stepped outside our
              comfort zone and to do something new.
              <br />
              <br />
              Due to confidentiality, I am unable to present the full project
              but am able to show an abstract piece of it.
            </p>
            <h3 className="text-lg font-semibold">Technologies</h3>
            <div className="flex flex-wrap gap-2">
              {technologies.map((tech) => {
                return <Tags name={tech.name} type={tech.type} />;
              })}
            </div>
          </div>
        </div>
      </section>
      <section className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20`}>
        <div
          className={`text-3xl xl:text-5xl border-b-2 pb-2 font-semibold border-gradient`}
          id="problem"
        >
          Problem
        </div>
        <div className="pt-4 h-auto flex flex-col gap-4">
          <p className="font-light lg:text-lg">
            The enterprise works with 3rd-party suppliers who require access to
            the enterprise network. Previously, various operating units and
            enabling functions (OU/EFs) managed their own guest access
            processes. The 'UK IT Access Portal' is an existing Power App,
            developed by the central UK IT team, to handle new account requests.
          </p>
          <h3 className="font-semibold">Issues</h3>
          <ul className="list-outside list-disc ml-5">
            <li>No centralised platform to request access</li>
            <li>No visibility of the status/stage of guest access</li>
            <li>Overly reliant on manual processes</li>
            <li>
              SharePoint list can be manually overwritten and has a maximum
              capacity of 2000 records
            </li>
            <li>No process to manage guest requests and account lifecycle</li>
            <li>E-mail notifications are often missed</li>
          </ul>
        </div>
      </section>
      <section
        className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20 bg-blue-950`}
      >
        <div
          className={`text-3xl xl:text-5xl border-b-2 pb-2 font-semibold border-gradient text-white`}
          id="requirements"
        >
          Requirements
        </div>
        <div className="pt-4 h-auto flex flex-col gap-4 text-white">
          <p className="font-light lg:text-lg">
            We gathered requirements from users with a business analyst and
            explored the feasibility of requirements. Then we determined their
            priority and placed them into phases of development. Here is a
            summary of the requirements for Phase 1 which was completed in a 2
            week sprint:
          </p>
          <ul className="list-outside list-disc ml-5 grid lg:grid-cols-2 gap-4">
            <li>
              Able to request all guests through a central portal (Teams,
              SharePoint, ArcGIS, Concept)
            </li>
            <li>
              Automatically add guest users to Microsoft Entra ID and relevant
              security groups
            </li>
            <li>The requester and approver will receive a emails</li>
            <li>
              Guest will receive an invitation e-mail with the requestor cc'd
            </li>
            <li className="line-through">
              Add a Non-Disclosure Agreement within the process
            </li>
            <li>
              Automation to present error messages and request process status
            </li>
          </ul>
        </div>
      </section>
      <section className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20`}>
        <div
          className={`text-3xl xl:text-5xl border-b-2 pb-2 font-semibold border-gradient`}
          id="solution"
        >
          Solution
        </div>
        <div className="pt-4 h-auto flex flex-col gap-4">
          <h3 className="text-lg font-semibold">
            Discussing Solutions and Wireframing
          </h3>
          <p className="font-light lg:text-lg">
            The team discussed solutions, balancing scope between user
            requirements and IT developement requirements, as well as low
            fidelity wireframing on Figma. We also created a high-level flow for
            the current solution and the new solution. Here is an abstraction
            created on Miro:{" "}
            <span
              className="text-sky-500 cursor-pointer"
              onClick={handleShowFlow}
            >
              {showFlow ? "Hide Flow" : "Show Flow"}
            </span>
          </p>

          <div>
            <Tabs className={showFlow ? "block" : "hidden"}>
              <TabList>
                <Tab>Old</Tab>
                <Tab>New</Tab>
              </TabList>

              <TabPanel>
                <div className="overflow-scroll image-container">
                  <img
                    src={CurrentGuestAccess}
                    alt="old flow"
                    className="flow-img"
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="overflow-scroll image-container">
                  <img
                    src={NewGuestAccess}
                    alt="new flow"
                    className="flow-img"
                  />
                </div>
              </TabPanel>
            </Tabs>
          </div>
          <h3 className="text-lg font-semibold">Building the Database</h3>
          <p className="font-light lg:text-lg">
            We moved from a SharePoint List into SQL database in compliance with
            business requirements and is much more secure. We built the schema
            to include data that fulfills both user and development
            requirements.
            <span
              className="text-green-500 cursor-pointer lg:text-lg"
              onClick={handleShowSchema}
            >
              {showSchema ? " Hide Schema" : " Show Schema"}
            </span>
          </p>
          <iframe
            width="100%"
            height="400px"
            style={{
              boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
              borderRadius: "15px",
            }}
            allowtransparency="true"
            allowfullscreen="true"
            title="Embedded DrawSQL IFrame"
            className={showSchema ? "block" : "hidden"}
            src="https://drawsql.app/teams/sheenas-team/diagrams/guest-acces/embed"
          ></iframe>
          <p className="lg:text-lg">
            The database and views were created in Azure Data Studio.{" "}
            <span
              className="text-amber-500 cursor-pointer lg:text-lg"
              onClick={handleShowCode}
            >
              {showCode ? "Hide Code" : "Show Code"}
            </span>
          </p>
          <div className={showCode ? "block" : "hidden"}>
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <h3 className="text-md font-semibold">Create table:</h3>
                <code className="code text-xs bg-gray-200">
                  CREATE TABLE [GuestAccess].[DomainRequests] (
                  <br />
                  <span className="ml-3"></span>[DomainID] INT IDENTITY (1, 1)
                  NOT NULL,
                  <br />
                  <span className="ml-3"></span>
                  [DomainName] VARCHAR (256) NULL,
                  <br />
                  <span className="ml-3"></span>[CreatedDate] DATETIME NULL,
                  <br />
                  <span className="ml-3"></span>[ModifiedDate] DATETIME NULL,
                  <br />
                  <span className="ml-3"></span>[Requestor] VARCHAR (256) NULL,
                  <br />
                  <span className="ml-3"></span>[VendorName] VARCHAR (256) NULL,
                  <br />
                  <span className="ml-3"></span>[RelationshipExpiry] VARCHAR
                  (512) NULL,
                  <br />
                  <span className="ml-3"></span>[GuestSurname] VARCHAR (256)
                  NULL,
                  <br />
                  <span className="ml-3"></span>[OUEF] INT NULL,
                  <br />
                  <span className="ml-3"></span>[DomainRequestStage] INT NULL,
                  <br />
                  <span className="ml-3"></span>[Approver] VARCHAR (256) NULL,
                  <br />
                  <span className="ml-3"></span>[ApproverComment] VARCHAR (256)
                  NULL,
                  <br />
                  <span className="ml-3"></span>[ApprovalDate] DATETIME NULL,
                  <br />
                  <span className="ml-3"></span>CONSTRAINT
                  [PK_GuestAccessRequestID] PRIMARY KEY CLUSTERED ([RequestID]
                  ASC) );
                </code>
              </div>
              <div>
                <h3 className="text-md font-semibold">Create view:</h3>
                <code className="code text-xs bg-gray-200">
                  ALTER VIEW [GuestAccess].[vw_NewDomainRequests] AS SELECT
                  GD.DomainID--,J1.lookupvalue AS "TypeofRequest"
                  <br />
                  <span className="ml-3"></span>,GD.DomainName
                  <br />
                  <span className="ml-3"></span>,GD.Requestor
                  <br />
                  <span className="ml-3"></span>,GD.VendorName
                  <br />
                  <span className="ml-3"></span>,GD.RelationshipExpiry
                  <br />
                  <span className="ml-3"></span>,GD.BusinessCase
                  <br />
                  <span className="ml-3"></span>,J2.LookupValue AS "OUEF" From
                  GuestAccess.GuestDomains AS GD INNER JOIN
                  GuestAccess.LookupValue AS J2 ON GD.OUEF=J2.LookupValueID
                  WHERE DomainRequestStage = 12
                  <br />
                  GO
                  <br />
                  SET ANSI_NULLS ON
                  <br />
                  GO
                  <br />
                  SET QUOTED_IDENTIFIER ON
                  <br />
                  Go
                  <br />
                  );
                </code>
              </div>
            </div>
          </div>
          <h3 className="text-lg font-semibold">App Development</h3>
          <p className="font-light lg:text-lg">
            This is my first Power Apps development, and I was able to
            successfully get and patch data to the SQL database, use switch/if
            statements to control the flow and set dynamic properties with
            variables. Here is a Figma prototype, which is an abstraction of the
            app without some of the intricacies. (Note: test in full screen){" "}
            <span
              className={`text-rose-600 cursor-pointer sm:hidden`}
              onClick={handleShowProto}
            >
              Show prototype
            </span>
          </p>
          <iframe
            title="Figma Prototype"
            style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
            id="figmaPrototype"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FT1aopIElEj7P2Sj7WO31NO%2FGuest-Access-Prototype%3Ftype%3Ddesign%26node-id%3D2-3%26t%3DF586Ugb38fPhZsMS-1%26scaling%3Dmin-zoom%26page-id%3D0%253A1%26starting-point-node-id%3D2%253A3%26mode%3Ddesign"
            allowfullscreen
            width="100%"
            height="600px"
            className={showProto ? "block" : "hidden sm:block"}
          ></iframe>
          <h3 className="text-lg font-semibold">Automating Processes</h3>
          <p className="font-light lg:text-lg">
            Power Automate was used to handle new submissions and approvals for
            both guest domains and guest accounts.
            <br />
            <br />
            SCROCH was used to handle the creation of accounts and adding them
            to Azure AD and security groups, as well as sending custom e-mail
            invites. Existing runbooks for creating accounts for other workflows
            were reused, while new runbooks for custom emails
          </p>
        </div>
      </section>
      <section
        className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20 bg-blue-950`}
      >
        <div
          className={`text-3xl xl:text-5xl border-b-2 pb-2 font-semibold border-gradient text-white`}
          id="Review"
        >
          Review
        </div>
        <div className="pt-4 h-auto flex flex-col gap-4 text-white">
          <p className="font-light lg:text-lg">
            A demo was presented to stakeholders in a sprint review, where we
            gathered some thoughts and feedback as well as got users to sign-up
            as a tester.
            <br />
            <br />
            One major feedback is the non-disclosure agreement, which was
            omitted from the initial product as it had no place in the process
            and was not monitored in any way. We learnt that this was a feature
            for the requestor to agree to take responsibility for rather than an
            actual step in the process. We took this feedback on board and added
            the policy and a checkbox to the form.
            <br />
            <br />
            Other feedback included featured we put into the next phases of
            development.
          </p>
        </div>
      </section>
    </>
  );
};

export default GuestAccess;
