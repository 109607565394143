import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./pages/Homepage";
import "./App.css";
import Navbar from "./components/Navbar";
import Aboutpage from "./pages/Aboutpage";
import Contact from "./components/Contact";
import Projectpage from "./pages/Projectpage";
import Contactpage from "./pages/Contactpage";
import UpperHandPoker from "./pages/UpperHandPoker";
import GuestAccess from "./pages/GuestAccess";
import { useEffect } from "react";

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="App">
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/about" element={<Aboutpage />} />
        <Route exact path="/projects" element={<Projectpage />} />
        <Route exact path="/contact" element={<Contactpage />} />
        <Route exact path="/projects/2" element={<UpperHandPoker />} />
        <Route exact path="/projects/0" element={<GuestAccess />} />
      </Routes>
      <Contact />
    </div>
  );
}

export default App;
