export const projectData = [
  {
    title: "Guest Access",
    subtitle: "Improving collaboration & workflow",
    description:
      "An enterprise product for employees to request for 3rd party guests to access the company network.",
    image: "GuestAccessRequest",
    technologies: [
      { name: "power apps", type: "client" },
      { name: "azure data studio", type: "database" },
      { name: "sql", type: "language" },
      { name: "power automate" },
    ],
    icons: [
      {
        icon: "OpenBook",
        link: "0/",
        alt: "Read More",
      },
    ],
  },
  {
    title: "Rain Check",
    subtitle: "Have you had a rain check? ",
    description:
      "This solo frontend web app build uses the OpenWeatherApp API, WeatherAPI, Google Times Zone API and Unsplash API.",
    image: "rain_check",
    technologies: [
      { name: "react", type: "client" },
      { name: "axios", type: "client" },
      { name: "tailwind css", type: "client" },
      { name: "netlify", type: "deployment" },
    ],
    icons: [
      {
        icon: "Web",
        link: "https://unique-palmier-9835d9.netlify.app/",
        alt: "Live",
      },
      {
        icon: "Github",
        link: "https://github.com/shedp/rain_check",
        alt: "Repo",
      },
    ],
  },
  {
    title: "Upper Hand Poker",
    subtitle: "A poker game with a twist!",
    description:
      "Flask-Sockets and Redux was used in building the game flow. Assets were a mixture of DALL-E and Photoshop.",
    image: "upperhand",
    technologies: [
      { name: "react", type: "client" },
      { name: "vite", type: "client" },
      { name: "tailwind css", type: "styling" },
      { name: "flask", type: "server" },
      { name: "sockets", type: "other" },
      { name: "sql alchemy", type: "database" },
    ],
    icons: [
      {
        icon: "OpenBook",
        link: "2/",
        alt: "Read More",
      },
      {
        icon: "Github",
        link: "https://github.com/liambrockpy/upper-hand-vite-client",
        alt: "Client Repo",
      },
      {
        icon: "Github",
        link: "https://github.com/liambrockpy/upper-hand-server",
        alt: "Server Repo",
      },
    ],
  },
  {
    title: "URL Shortener",
    subtitle: "A URL shortener for lovers of pink! ",
    description:
      "This is a URL shortener created with Python (Flask) and Jinga for the frontend. It uses an SQL database (SQLAlchemy).",
    image: "urlshorten",
    technologies: [
      { name: "jinga2", type: "client" },
      { name: "flask", type: "server" },
      { name: "sql alchemy", type: "database" },
      { name: "python", type: "language" },
      { name: "javascript", type: "language" },
    ],
    icons: [
      {
        icon: "Github",
        link: "https://github.com/shedp/pythonURLShortener",
        alt: "Repo",
      },
    ],
  },
  {
    title: "Ye Olde Quiz",
    subtitle: "Ye Old Quiz brings the pub quiz to your homes. ",
    description:
      "This retro-style multiplayer quiz game uses Socket.io and a React.js frontend with Node.Js and Express for the backend.",
    image: "yeoldequiz",
    technologies: [
      { name: "react", type: "client" },
      { name: "express", type: "server" },
      { name: "node", type: "server" },
      { name: "sockets", type: "server" },
      { name: "postgresql", type: "database" },
    ],
    icons: [
      {
        icon: "Github",
        link: "https://github.com/PollyFenne/Ye_olde_quiz_frontend",
        alt: "Client Repo",
      },
      {
        icon: "Github",
        link: "https://github.com/shedp/YeOldeQuizBackend",
        alt: "Server Repo",
      },
    ],
  },
  {
    title: "Git Tracker",
    subtitle: "Octodog > Octocat. (Dogs are better trackers!)",
    description:
      "Ssearch for Github users and view their stats such as repos and languages using the Github API and React for the frontend. It uses ChartJS to visualize the language usage by the user.",
    image: "gittracker",
    technologies: [
      { name: "React", type: "client" },
      { name: "redux", type: "client" },
      { name: "chartjs", type: "client" },
      { name: "javascript", type: "language" },
    ],
    icons: [
      {
        icon: "Github",
        link: "https://github.com/shedp/GithubTrackerReact",
        alt: "Repo",
      },
    ],
  },
  {
    title: "Habitual",
    subtitle: "Turn your rituals into good habits. ",
    description:
      "Habitual is a habit tracker created using NodeJS, ExpressJS, authentication, and a postgreSQL database",
    image: "habitual",
    technologies: [
      { name: "express", type: "server" },
      { name: "node", type: "server" },
      { name: "bcryt", type: "server" },
      { name: "postgresql", type: "database" },
      { name: "jest", type: "testing" },
      { name: "render", type: "deployment" },
      { name: "netlify", type: "deployment" },
    ],
    icons: [
      {
        icon: "Web",
        link: "https://relaxed-speculoos-a7d774.netlify.app/client/login.html",
        alt: "Live",
      },
      {
        icon: "Github",
        link: "https://github.com/JLP2000/habitual-client",
        alt: "Client Repo",
      },
      {
        icon: "github",
        link: "https://github.com/JLP2000/habitual-server",
        alt: "Server Repo",
      },
    ],
  },
  {
    title: "Liberty",
    subtitle: "For your unconventional ideas",
    description:
      "An anonymous blogging app created with a mongoDB and Mongoose. It was created with vanilla CSS and Javascript.",
    image: "liberty",
    technologies: [
      { name: "express", type: "server" },
      { name: "node", type: "server" },
      { name: "mongodb", type: "database" },
      { name: "mongoose", type: "database" },
      { name: "javascript", type: "language" },
    ],
    icons: [
      {
        icon: "Github",
        link: "https://github.com/shedp/liberty-blog",
        alt: "Repo",
      },
    ],
  },
  //   {
  //     title: "Python",
  //     subtitle: "A classic game of Python! ",
  //     description:
  //       "This is the classic game of Snake created with Pygame and python.",
  //     image: "python",
  //     technologies: [
  //       { name: "pygame", type: "other" },
  //       { name: "python", type: "languages" },
  //     ],
  //     icons: [
  //       {
  //         icon: "github",
  //         link: "https://github.com/shedp/pygameSnake",
  //         alt: "Repo",
  //       },
  //     ],
  //   },
  // {
  //   title: "preVent",
  //   subtitle: "Vent to Prevent! ",
  //   description:
  //     "This is an anonymous blogging app that encourages you to vent out your frustrations. It utilises a GIF api and was created with vanilla CSS and Javascript.",
  //   image: "prevent",
  //   technologies: [
  //     { name: "javascript", type: "languages" },
  //     { name: "css", type: "languages" },
  //     { name: "html", type: "languages" },
  //   ],
  //   icons: [
  //     {
  //       icon: "web",
  //       link: "https://dreamy-jalebi-cb99ed.netlify.app/client/index.html",
  //       alt: "Live",
  //     },
  //     {
  //       icon: "github",
  //       link: "https://github.com/shedp/preVent",
  //       alt: "Repo",
  //     },
  //   ],
  // },
];

export default projectData;
