import React from "react";
import GuestAccessRequest from "../assets/projects/GuestAccessRequest.png";
import rain_check from "../assets/projects/rain_check.png";
import upperhand from "../assets/projects/upperhand.png";
import habitual from "../assets/projects/habitual.png";
import liberty from "../assets/projects/liberty.png";
import python from "../assets/projects/python.png";
import urlshorten from "../assets/projects/urlshorten.png";
import yeoldequiz from "../assets/projects/yeoldequiz.png";
import gittracker from "../assets/projects/gittracker.png";
import panda from "../assets/panda.png";

const Image = ({ project }) => {
  switch (project.image) {
    case "GuestAccessRequest":
      return (
        <img
          src={GuestAccessRequest}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "rain_check":
      return (
        <img
          src={rain_check}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "upperhand":
      return (
        <img
          src={upperhand}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "habitual":
      return (
        <img
          src={habitual}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "liberty":
      return (
        <img
          src={liberty}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "python":
      return (
        <img
          src={python}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "urlshorten":
      return (
        <img
          src={urlshorten}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "yeoldequiz":
      return (
        <img
          src={yeoldequiz}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    case "gittracker":
      return (
        <img
          src={gittracker}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
    default:
      return (
        <img
          src={panda}
          alt={project.title}
          className="w-full h-full object-cover object-center transition duration-300"
        />
      );
  }
};

export default Image;
