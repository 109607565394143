import React from "react";
import projectData from "../db/db";
import Tags from "../components/Tags";
import IconBig from "../components/IconBig";

const UpperHandPoker = () => {
  const url = window.location.href;
  const index = url.indexOf("/projects/");
  let id = url[index + 10];

  const technologies = [
    { name: "react", type: "client" },
    { name: "vite", type: "client" },
    { name: "redux", type: "client" },
    { name: "tailwind css", type: "client" },
    { name: "flask", type: "server" },
    { name: "redis", type: "server" },
    { name: "sql alchemy", type: "database" },
    { name: "sql lite", type: "database" },
    { name: "render", type: "deployment" },
    { name: "netlify", type: "deployment" },
    { name: "vite test", type: "testing" },
    { name: "pytest", type: "testing" },
    { name: "javascript", type: "language" },
    { name: "python", type: "language" },
    { name: "sockets", type: "other" },
    { name: "dall-e", type: "other" },
    { name: "figma", type: "other" },
  ];

  const links = [
    {
      icon: "Github",
      link: "https://github.com/liambrockpy/upper-hand-vite-client",
      alt: "Client Repo",
    },
    {
      icon: "Github",
      link: "https://github.com/liambrockpy/upper-hand-server",
      alt: "Server Repo",
    },
  ];

  return (
    <section
      className={`w-full px-12 sm:px-20 md:px-24 lg:px-36 py-20 bg-blue-950 text-white`}
    >
      <div className={`border-b-2 text-3xl xl:text-5xl mb-10 font-semibold`}>
        {projectData[id].title}
      </div>

      <div className="grid grid-col-1 lg:grid-cols-12 gap-4 text-white">
        <div className="lg:col-span-5 flex flex-col gap-4">
          <p className="pl-2 font-light lg:text-lg">
            Upper Hand Poker was my final group project from my bootcamp, which
            was presented to potential employers across different industries.
          </p>
          <h3 className="text-lg font-semibold lg:text-xl">Brief</h3>
          <p className="pl-2 lg:text-lg">
            Any creative idea with a Python backend server!
          </p>
          <h3 className="text-lg font-semibold lg:text-xl">Technologies</h3>
          <div className="flex flex-wrap gap-2">
            {technologies.map((tech) => {
              return <Tags name={tech.name} type={tech.type} />;
            })}
          </div>
          <h3 className="text-lg font-semibold mt-2 lg:text-xl">Links</h3>
          <div className="flex justify-start gap-5">
            {links.map((icon, i) => {
              return <IconBig icon={icon} key={i} id={id} />;
            })}
          </div>
        </div>
        <div className="lg:col-span-7 items-center">
          <iframe
            id="uhp_pres"
            src="https://onedrive.live.com/embed?resid=3F7A6E630F89AAF9%212087&amp;authkey=!AIwHSkABWOePM4A&amp;em=2&amp;wdAr=1.7777777777777777"
            className="w-full min-h-72 lg:in-h-80 xl:min-h-96"
            frameborder="0"
            title="Presentation"
          >
            This is an embedded{" "}
            <a target="_blank" rel="noreferrer" href="https://office.com">
              Microsoft Office
            </a>{" "}
            presentation, powered by{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://office.com/webapps"
            >
              Office
            </a>
            .
          </iframe>
        </div>
      </div>
    </section>
  );
};

export default UpperHandPoker;
